import React, { Component, PropTypes } from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { Input, Select, Icon } from 'antd';
import { setSearchText, setSearchCriteria, setSearchType } from '../../redux/reducer/searchReducer';
import { getOrgId } from '../../helpers/commonFunctions';
import { getTaskTypesOrgProject } from '../../Services/WorkspaceApis';
import { taskTypeMap } from '../../helpers/Utils';
const { Option } = Select;
const InputGroup = Input.Group;
class Search extends Component {
    static propTypes = {
        projectWorkspaceList: PropTypes.array,
        pushState: PropTypes.func,
        searchText: PropTypes.string,
        searchCriteria: PropTypes.string,
        searchType: PropTypes.string
    }
    constructor(props) {
        super(props)
        const  orgId  = getOrgId()
        let search = window.location.search;
        let params = new URLSearchParams(search);
        let queries = params.get('query');
        let types = params.get('type');
        this.state = {
            orgId,
            data: this.props.projectWorkspaceList,
            filteredData: [],
            query: queries ? props.searchText : '',
            toggleDropdown: false,
            searchCriteria: props.searchCriteria,
            searchType: types ? props.searchType : '',
            types: []
        }
    }
    componentDidMount() {
        const { types, orgId } = this.state;
        if (types && types.length === 0) {
            if (this.state && orgId) {
                getTaskTypesOrgProject(orgId, this.taskTypeFetchedCallback)
            }
        }
        if ( window.localStorage.getItem("searchClear") === '1') {
        this.setState({searchCriteria: 'text', query: ''})
        window.localStorage.setItem('searchClear', '0');
       }
      }
    componentDidUpdate(prevProps) {
        if (prevProps.searchText !== this.props.searchText)
            this.setState({query: this.props.searchText})
        if (prevProps.searchType !== this.props.searchType)
            this.setState({searchType: this.props.searchType})
    }
    taskTypeFetchedCallback = (error, response) => {
        if (!error) {
             console.log("Response search",response.body)
              this.setState({types: response.body });
        }
    }
    handleSearchChange = event => {
        const {projectWorkspaceList} = this.props
        const query = event.target.value;
        this.setState(prevState => {
        const filteredData = projectWorkspaceList && projectWorkspaceList.filter(element => {
            if (query.length > 0)
                return element.name.toLowerCase().includes(query.toLowerCase());
          });
          return {
            query,
            filteredData
          };
        });
        this.handleToggleDropdown(true)
    };

    handleRedirection = query => {
        this.props.setSearchText(query)
        this.props.setSearchType('');
        this.props.pushState({
            pathname:
                "/searchview",
            query: { query }
        });
        this.handleToggleDropdown(false)
    }
    searchProjectWorkspace = (e) => {
        e.preventDefault()
        const { query } = this.state
        this.props.setSearchText(query)
        this.props.setSearchType('');
        this.props.pushState({
            pathname:
                "/searchview",
            query: { query }
        });
        this.handleToggleDropdown(false)
    }
    handleToggleDropdown = (value) => {
        this.setState({toggleDropdown: value})
    }
    change = value => {
        if ( value !== '' ) {
            this.setState({searchCriteria: value});
            this.props.setSearchCriteria(value);
        }
        if  (value === 'text')
            this.props.setSearchType('');
        if  (value === 'type'){
            this.setState({query: ''});
            this.props.setSearchText('');
        }
    }
    processSearchType = type => {
        this.setState({searchType: type});
        this.props.setSearchText('');
        this.props.setSearchType(type);
        let isFilter = 1
        this.props.pushState({
            pathname:
                "/searchview",
            query: { type, isFilter }
        });
    }
    render() {
        const { filteredData, query, toggleDropdown, types,searchType, searchCriteria } = this.state
        let typesList = types.length > 0 && types.map((item, i) => (
            <option key={i} value={item}>{taskTypeMap[item]}</option>
          ));
        return (
            <div className="search_wrap">
                <div className="search_sec">
                    <InputGroup compact className="search_select">
                    <Select className="search_txt" defaultValue="text" onChange={this.change} value={searchCriteria}>
                        <Option value="" disabled selected>Select Type</Option>
                        <Option value="text" >Search by Text</Option>
                        <Option value="type">Search by Project</Option>
                    </Select>
                    {searchCriteria === "text" && <form className="form_search">
                    <input type="text" className="searchTerm"
                        placeholder="Search for workspace and project"
                        onChange={(e) => this.handleSearchChange(e)}
                        value={query}
                    />
                    <button type="submit" onClick={(e) => this.searchProjectWorkspace(e)} className="searchButton">
                            <Icon type="search" />
                    </button></form>}
                    {searchCriteria === "type" &&
                    <Select className="search_type" defaultValue="image_classification" onChange={this.processSearchType} value={searchType}>
                    <Option value="" disabled selected>Select Type</Option>
                        {typesList}
                    </Select>}
                    </InputGroup>
                </div>
                <div className={"search_drop " + (toggleDropdown && searchCriteria === "text" &&  (query && query.length > 0) ? '' : 'drop-hide')}>
                { filteredData && filteredData.length === 0 &&
                    <p className="blank_text">No results found...</p>
                }
                {filteredData && filteredData.length > 0 ? filteredData.map(i => <p className="list_holder" onClick={() => this.handleRedirection(i.name)}><span className={"s_word " + (i.type === 'workspace' ? 'w_word' : 'p_word')} >{i.type === 'workspace' ? 'W' : 'P'}</span><h5 className={"base_txt " + (i.name.length > 55 ? 'divpara_wrap' : '')} style={{ marginTop : "0px"}}>{(i.name)}</h5></p>) : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    console.log("Search state from redux",state?.searchReducer)
    return {
        projectWorkspaceList: state?.searchReducer?.projectWorkspaceList,
        searchText: state.searchReducer.searchText,
        searchCriteria: state.searchReducer.searchCriteria,
        searchType: state.searchReducer.searchType
    };
  };

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        pushState: push,
        setSearchText,
        setSearchCriteria,
        setSearchType
    },
    dispatch,
  )

export default connect(mapStateToProps, mapDispatchToProps) (Search);
